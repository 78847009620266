import React, { memo, useMemo, useRef, useState } from "react";
import "./App.css";
import { useControls, fill, Leva, button } from "leva";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  OrbitControls,
  OrthographicCamera,
  Stage,
  useGLTF,
} from "@react-three/drei";

const Box = () => {
  const mesh = useRef(null);
  const { nodes } = useGLTF("/GLB/assets-compressed.gltf");
  const names = Object.keys(nodes);
  names.shift(0);

  // const {scale} = useControls("Scale",{scale: 1})

  //DropDown code
  // const object = useControls({
  //   value: { options: { Cylinder: 1, Sphere: 2 } },
  // });

  const colorVal = ["hotpink", "orange", "lightblue", "green", "red", "lime"];
  const randomColor = colorVal[Math.floor(Math.random() * colorVal.length)];
  const { color } = useControls({ color: randomColor }); //useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.001));
  const { xPos } = useControls({
    xPos: { value: 0, min: -5, max: 5, step: 1 },
  });
  const { yPos } = useControls({
    yPos: { value: 0, min: -5, max: 5, step: 1 },
  });
  const { zPos } = useControls({
    zPos: { value: 0, min: -5, max: 5, step: 1 },
  });
  const mintButton = useControls({
    number: 3,
    mint: button(
      (get) => mint(),
      (get) =>
        alert(
          "Minting has begun, follow steps in your wallet, this may take a few moments..."
        )
    ),
  });

  function mint() {
    console.log("Minting...");
  }

  useFrame(() => {
    mesh.current.x += 0.01;
  });
  const { object } = useControls("Object", {
    object: {
      value: 4,
      min: 0,
      max: names.length - 1,
      step: 1,
    },
  });

  console.log(names[object]);
  return (
    <mesh
      ref={mesh}
      position={[xPos, yPos, zPos]}
      scale={1}
      castShadow
      receiveShadow
      color={color}
      geometry={nodes[names[object]].geometry}
    >
      <meshStandardMaterial attach="material" color={color} />
    </mesh>
  );
};

function App() {
  const colorVal = ["hotpink", "orange", "lightblue", "green", "red", "lime"];
  const randomColor = colorVal[Math.floor(Math.random() * colorVal.length)];
  const { color } = useControls("Background", { color: randomColor });
    const { x, y, z } = useControls("Generative Values", {
    x: { value: 5, min: 0, max: 10, step: 1 },
    y: { value: 5, min: 0, max: 10, step: 1 },
    z: { value: 5, min: 0, max: 10, step: 1 },
  });

  function Qubit({ position, state, rotation }) {
    const mesh = useRef();
    const { nodes } = useGLTF("/GLB/assets-compressed.gltf");
    const names = Object.keys(nodes);
    names.shift(0);
    const { object, scale } = useControls( "Generative Values", {
      object: {
        value: 4,
        min: 0,
        max: names.length - 1,
        step: 1,
      },
      scale: {
        value: 1,
        min: 0,
        max: 1,
        step: 0.1,
      },
    });

    return (
      <mesh
        ref={mesh}
        position={position}
        scale={scale}
        geometry={nodes[names[object]].geometry}
        rotation={rotation}
      >
        <boxBufferGeometry args={[1, 1, 1]} />
        <meshStandardMaterial
          attach="material"
          color={state === "up" ? "hotpink" : "lime"}
        />
      </mesh>
    );
  }

  function Scene() {
    const qubits = useRef([]);

    useFrame(() => {
      // Update qubits based on wave function collapse algorithm
      qubits.current.forEach((qubit) => {
        // Update qubit state based on algorithm rules
        qubit.state = Math.random() > 0.5 ? "up" : "down";
        // Update qubit position based on state
        qubit.position.y = qubit.state === "up" ? 1 : -1;
        qubit.position.z = qubit.state === "up" ? 1 : -1;
      });
    });

    return (
      <group>
        {[...Array(x)].map((_, i) =>
          [...Array(y)].map((__, j) =>
            [...Array(z)].map((__, k) => (
              <Qubit
                key={`${i}-${j}-${k}`}
                ref={(el) => qubits.current.push(el)}
                position={[i * 1.1, j * 1.1, k * 1.1]}
                rotation={[
                  Math.random() * 2,
                  Math.random() * 9,
                  Math.random() * 10,
                ]}
                state={Math.random() > 0.5 ? "up" : "down"}
              />
            ))
          )
        )}
      </group>
    );
  }
  return (
    <>
      <Canvas shadows style={{ background: color }}>
        <Stage rembrandt>
          <Scene />
          <OrthographicCamera makeDefault zoom={50} />
          <OrbitControls target={[0, 4, 0]} panSpeed="0" />
        </Stage>
      </Canvas>
      <div className="h-full w-full bg-transparent z-10">
        <div className="absolute top-72 right-20">
          <Leva fill />
        </div>
      </div>
    </>
  );
}

export default App;
