import React from "react";
import { ConnectWallet } from "@thirdweb-dev/react";
import { ChainId, ThirdwebProvider } from "@thirdweb-dev/react";
import Logo from "./Logo.svg";

const activeChainId = ChainId.Goerli;

const Navbar = () => {
  const colorVal = ["hotpink", "orange", "green", "red", "yellow"];
  const randomColor = colorVal[Math.floor(Math.random() * colorVal.length)];
  return (
      <div className="w-full h-24 bg-transparent fixed z-10">
        <div className="flex justify-between h-24 p-20 items-center">
          <img className="hover:cursor-pointer" onClick={()=> window.open("https://playfactory.app")} src={Logo} fill="purple" color={randomColor} alt="Logo" />
          <div className="flex justify-between items-center">
            <img className="justify-end" alt="Profile" src="/Profile.png"></img>
          </div>
        </div>
      </div>
  );
};

export default Navbar;
